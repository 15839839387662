import React from 'react';
import Button from '@components/button';
import Banner from './Banner';
import styles from './banner-cta.module.scss';

const BannerCta = props => {
	const { title, link, cta, id } = props;

	return (
		<Banner isLarge smallContainer={props.smallContainer}>
			<div className={styles.container}>
				<p className={styles.title}>{title}</p>
				<div className={styles.cta}>
					<Button id={id} className={styles.ctaButton} negative link={link} title={cta}>
						{cta}
					</Button>
				</div>
			</div>
		</Banner>
	);
};

export default BannerCta;
