import React from 'react';
import Layout from '@components/layout';
import Heading from '@components/heading';
import Intro from '@components/intro';
import Jobs from '@components/jobs';

const Careers = (props) => {
	const { location, pageContext } = props;
	const { title, subtitle } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading
				title={title}
				subtitle={subtitle}
				h1Title
				h2Subtitle
				imageAlt={'Nearshore Software Developers | Uruit'}
			/>
			<Intro {...pageContext.intro} hasWhiteBackground h3Title />
			<Jobs {...pageContext.jobs} />
		</Layout>
	);
};

export default Careers;
