import React from 'react';
import Layout from '@components/layout';
import Heading from '@components/heading';
import ContactUs from '@components/contact-us';
import Banner from '@components/banner';
import Link from '@components/link';
import styles from './contact.module.scss';

const Contact = props => {
	const { location, pageContext } = props;
	const { title, subtitle } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} subtitle={subtitle} />
			<ContactUs id="contact-from-contact" pathname={location.pathname} showHeading={false} />
			<Banner>
				<p>
					If you prefer you can also contact our offices or email us at
					<Link className={styles.link} to={'mailto:hello@uruit.com'}>
						{' '}
						hello@uruit.com
					</Link>
					.
				</p>
			</Banner>
		</Layout>
	);
};

export default Contact;
