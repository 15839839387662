import React from 'react';
import { Layout, Container } from '@components';
import Link from '@components/link';
import styles from './404.module.scss';
import Picture from '@components/picture';

const Links = props => {
	const { links } = props;

	return (
		links &&
		links.list.length && (
			<div className={styles.links}>
				<h3 className={styles.linksTitle}>{links.title}</h3>
				<ul className={styles.linksList}>
					{links.list.map((link, i) => {
						return (
							<li key={i} className={styles.linkItem}>
								<Link {...link} className={styles.link}>
									{link.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		)
	);
};

const NotFound = props => {
	const { location, pageContext } = props;
	const { title, subtitle, image, links } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext} hideLocations padded>
			<section className={styles.section}>
				<Container className={styles.container}>
					{image && <Picture className={styles.media} imgClassName={styles.image} sources={image} />}
					<div className={styles.content}>
						<h2 className={styles.title}>{title}</h2>
						<p className={styles.subtitle}>{subtitle}</p>
						<Links links={links} />
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default NotFound;
