import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from '@components/container';
import SectionDescription from '@components/section-description';
import Filter from './filter';
import styles from './filters.module.scss';

const Filters = props => {
	const { content, sectionTitle, onFilter } = props;
	let tagsSelected = [];

	const handleFilter = useCallback(
		wordKey => {
			const indexTag = tagsSelected.indexOf(wordKey);
			if (indexTag > -1) {
				tagsSelected.splice(indexTag, 1);
			} else {
				tagsSelected.push(wordKey);
			}
			onFilter(tagsSelected);
		},
		[tagsSelected, onFilter]
	);

	return !!content ? (
		<section>
			<Container>
				<SectionDescription sectionTitle={sectionTitle} />
				<div className={styles.filterContainer}>
					{content &&
						content.map(({ label, tags }, i) => (
							<Filter key={i} label={label} words={tags} onFilter={handleFilter} isClickeable />
						))}
				</div>
			</Container>
		</section>
	) : null;
};

Filters.propTypes = {
	content: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			tags: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }).isRequired)
		})
	),
	sectionTitle: PropTypes.string,
	onFilter: PropTypes.func.isRequired
};

export default Filters;
