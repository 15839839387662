import React, { useState } from 'react';

import { classNames } from '@utils';
import SectionDescription from '@components/section-description';
import styles from './section.module.scss';

const values = {
	yes: 2,
	sometimes: 1,
	no: 0,
	noClue: 0
};
export default function Section({ title, id, subtitle, questions, answerSection, image }) {
	const [answers, setAnswers] = useState({ [id]: {} });
	const answerQuestion = (answer, question) => {
		const result = answers;
		result[id][question] = answer;
		setAnswers(result);
		answerSection(result);
	};

	return (
		<div className={styles.container}>
			<div className={styles.sectionDescription}>
				<SectionDescription sectionTitle={title} />
			</div>
			<div className={styles.section}>
				<div className={styles.description}>
					<p>{subtitle}</p>
					{image && <img className={styles.picture} src={image} />}
				</div>
				<div className={styles.questions}>
					{questions.map((question, ix) => (
						<Question key={ix} id={ix} title={question} answerQuestion={answerQuestion} />
					))}
				</div>
			</div>
		</div>
	);
}

const Question = ({ title, id, answerQuestion }) => {
	const [selected, setSelected] = useState('');
	return (
		<div className={styles.question}>
			<p className={styles.question}>{title}</p>
			<div className={styles.actions}>
				<button
					className={classNames(selected === 'yes' && styles.selected)}
					onClick={() => {
						setSelected('yes');
						answerQuestion(values.yes, id);
					}}
				>
					Yes
				</button>
				<button
					className={classNames(selected === 'no' && styles.selected)}
					onClick={() => {
						setSelected('no');
						answerQuestion(values.no, id);
					}}
				>
					No
				</button>
				<button
					className={classNames(selected === 'sometimes' && styles.selected)}
					onClick={() => {
						setSelected('sometimes');
						answerQuestion(values.sometimes, id);
					}}
				>
					Sometimes
				</button>
				<button
					className={classNames(selected === 'noClue' && styles.selected)}
					onClick={() => {
						setSelected('noClue');
						answerQuestion(values.noClue, id);
					}}
				>
					No clue
				</button>
			</div>
		</div>
	);
};
