import React from 'react';

import Layout from '@components/layout';
import WeAre from '@components/we-are';
import LAHeadquarters from '@components/l-a-headquarters';
import LASanDiego from '@components/l-a-san-diego';
import CasesPortfolioView from '@components/cases-portfolio-view';
import CarouselQuote from '@components/carousel-quote';
import Capabilities from '@components/capabilities';
import Clients from '@components/clients';
import ContactUs from '@components/contact-us';
import { Breakpoints } from '@utils';
import styles from './los-angeles.module.scss';

const LosAngeles = ({ location, pageContext }) => {
	const { intro: capabilitiesIntro, ...restCapabilities } = pageContext['capabilities'];
	const capabilities = {
		intro: { ...capabilitiesIntro, isFullWidthDescription: true, hasWhiteBackground: true, h3Title: true },
		noMarginBottom: true,
		whiteBackground: true,
		...restCapabilities
	};

	return (
		<Layout location={location} pageContext={pageContext}>
			{pageContext['we-are'] && (
				<Breakpoints>
					{(breakpoint) => {
						const column = ['xs', 'sm'].includes(breakpoint);
						return (
							<WeAre
								className={styles.weAre}
								{...pageContext['we-are']}
								animate
								home
								column={column}
								figureClassName={styles.figure}
								illustrations={'/cms/assets/la-hero-illustration.svg'}
								h1Title
								imageAlt={'Los Angeles software development company | Uruit'}
							/>
						);
					}}
				</Breakpoints>
			)}
			{pageContext['headquarters'] && <LAHeadquarters {...pageContext['headquarters']} />}
			{pageContext['san-diego'] && <LASanDiego {...pageContext['san-diego']} />}
			{pageContext['our-work'] && <CasesPortfolioView {...pageContext['our-work']} />}
			{pageContext['reviews'] && <CarouselQuote {...pageContext['reviews']} />}
			{pageContext['capabilities'] && (
				<div className={styles.capabilities}>
					<Capabilities {...capabilities} />
				</div>
			)}
			{pageContext['clients'] && <Clients {...pageContext['clients']} />}
			<ContactUs id="contact-from-los-angeles-landing" pathname={location.pathname} />
		</Layout>
	);
};

export default LosAngeles;
