import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfiniteCarousel from '@components/infinite-carousel';
import styles from './clients.module.scss';
import Picture from '@components/picture';

const Client = (props) => {
	const { image, name } = props;
	return !!image ? (
		<div className={styles.slide}>
			<Picture sources={image} alt={name} />
		</div>
	) : null;
};

Client.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string
};

const Clients = (props) => {
	const { title, items } = props;

	return !!items && items.length ? (
		<Fragment>
			<section className={styles.clients}>
				<div className={styles.title}>{title}</div>
				<InfiniteCarousel items={items} Component={Client} />
			</section>
		</Fragment>
	) : null;
};

Clients.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.array
};

Clients.defaultProps = {
	title: ''
};

export default Clients;
