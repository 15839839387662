import React from 'react';
import { ReactMarkdown } from '@components';

import Picture from '@components/picture';
import styles from './l-a-san-diego.module.scss';

const LASanDiego = ({ title, description, image, imageAlt }) => (
	<section className={styles.section}>
		<div className={styles.media}>
			{image && <Picture imgClassName={styles.img} alt={imageAlt} sources={image} />}
		</div>
		<div className={styles.content}>
			<h3 className={styles.title}>{title}</h3>
			<ReactMarkdown className={styles.description} children={description} />
		</div>
	</section>
);

export default LASanDiego;
