import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Tab from './tab';
import styles from './tabs.module.scss';

const Tabs = ({ tabs }) => {
	const [activeTab, setActiveTab] = useState(0);

	const handleSelectedTab = useCallback(id => {
		setActiveTab(id);
	});

	return (
		<div className={styles.tabs}>
			{tabs.map((tab, i) => (
				<Tab key={i} id={i} isActive={activeTab === i} onSelectedTab={handleSelectedTab} {...tab} />
			))}
		</div>
	);
};

Tabs.propTypes = {
	tabs: PropTypes.array.isRequired
};

export default Tabs;
