import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper.scss';
import styles from './recognitions.module.scss';
import Container from '@components/container';
import SectionTitle from '@components/section-title';
import Link from '@components/link'
import Picture from '../picture';

const Recognitions = (props) => {
    const { items, title } = props;
    return (
        <Fragment>
           <Container small>
            {!!title && (<SectionTitle title={title} />)}
            {!!items && items.length ? (
                <div className={styles.recognitions}>
                    {items.map(item =>
                        <div className={styles.recognitionItem}>
                            <Link to={item.link} target='new-tab'>
                                <Picture sources={item.image} alt={item.name} />
                            </Link>
                        </div>
                    )}
                </div>
            ) : null}
            </Container>
    </Fragment>
    )
}

Recognitions.propTypes = {
	items: PropTypes.array,
    title: PropTypes.string
};

Recognitions.defaultProps = {
	items: [],
    title: ''
};

export default Recognitions;
