import React from 'react';
import CasesPortfolioView from '@components/cases-portfolio-view';
import ContactUs from '@components/contact-us';
import Container from '@components/container';
import Heading from '@components/heading';
import Intro from '@components/intro';
import Layout from '@components/layout';
import OfferingIntro from '@components/offering-intro';
import Picture from '@components/picture';
import ProductFeaturesGrid from '@components/product-features-grid';
import SectionDescription from '@components/section-description';
import TitleWithChecklist from '@components/title-with-checklist';
import Tools from '@components/tools';
import YouAreHere from '@components/you-are-here';
import styles from './product-design.module.scss';

const Achievements = ({ achievements }) => (
	<div className={styles.achievements}>
		{achievements.map((achivement, i) => (
			<div key={i} className={styles.achivement}>
				<Picture sources={achivement.image} alt={achivement.text} />
				<p className={styles.achievementText}>{achivement.text}</p>
			</div>
		))}
	</div>
);

const ProductDesign = (props) => {
	const { location, pageContext } = props;
	const { title, intro, benefits, tools } = pageContext;
	const { img: howWeDoItImg, ...howWeDoIt } = pageContext['how-we-do-it'];
	const firstSection = pageContext['intro-section'];

	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} h1Title imageAlt={'Product Strategy | Uruit'} />
			<OfferingIntro {...intro} />
			<YouAreHere />
			<Container className={styles.firstSectionContainer} small>
				{firstSection['first-paragraph'] && (
					<div className={styles.firstParagraph}>
						<SectionDescription description={firstSection['first-paragraph']} />
					</div>
				)}
				{firstSection['highlight-paragraph'] && (
					<h3 className={styles.highlightParagraph}>{firstSection['highlight-paragraph']}</h3>
				)}
				{firstSection['achievements'] && (
					<Achievements achievements={firstSection['achievements']}></Achievements>
				)}
			</Container>
			{benefits && <TitleWithChecklist {...benefits} />}
			{howWeDoItImg &&
				<div className={styles.parallax} style={{ backgroundImage: `url(${howWeDoItImg})`}}/>}
			{howWeDoIt && <Intro {...howWeDoIt} h2Title hasWhiteBackground is8ColumnsWidthDescription />}
			{pageContext['product-features'] && <ProductFeaturesGrid {...pageContext['product-features']} />}
			{pageContext['case-studies'] && <CasesPortfolioView showLinkToPortfolio {...pageContext['case-studies']} />}
			{tools && <Tools className={styles.tools} {...tools} />}
			<ContactUs id="contact-from-product-design" pathname={location.pathname} />
		</Layout>
	);
};

export default ProductDesign;
