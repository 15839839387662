import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/button';
import styles from './machine-learning-consulting.module.scss';
import Picture from '@components/picture'

const MachineLeargingConsulting = ({ text, cards, button }) => (
	<div>
		<p className={styles.text}>{text}</p>
		<div className={styles.cardsContainer}>
			{cards.map(({ image, text }) => (
				<div className={styles.card} key={text}>
					{image && <Picture sources={image} alt={text} />}
					<p className={styles.cardText}>{text}</p>
				</div>
			))}
		</div>
		<Button filled className={styles.button} link={button.link} title={button.title}>
			{button.title}
		</Button>
	</div>
);

MachineLeargingConsulting.propTypes = {
	text: PropTypes.string.isRequired,
	cards: PropTypes.arrayOf(PropTypes.object).isRequired,
	button: PropTypes.object.isRequired
};

export default MachineLeargingConsulting;
