import Heading from '@components/heading';
import Intro from '@components/intro';
import styles from './ux-quiz.module.scss';
import Layout from '@components/layout';
import Container from '@components/container';
import Quiz from '@components/quiz/Quiz';
import React, { useState } from 'react';

const steps = {
	intro: 'intro',
	quiz: 'quiz'
};

const UxQuiz = (props) => {
	const { location, pageContext } = props;
	const { title, subtitle, sections } = pageContext;
	const [step, setStep] = useState(steps.quiz);
	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading
				title={title}
				subtitle={subtitle}
				h1Title
				h2Subtitle
				imageAlt={'Nearshore Software Developers | Uruit'}
			/>
			{step === steps.intro && (
				<>
					<Intro {...pageContext.intro} hasWhiteBackground h3Title />
					<Container>
						<button className={styles.button} onClick={() => setStep(steps.quiz)}>
							Take the Quiz
						</button>
					</Container>
				</>
			)}
			{step === steps.quiz && <Quiz sections={sections} />}
		</Layout>
	);
};

export default UxQuiz;
