import { getRelatedCases, NUMBER_OF_CASES } from '@utils';
import React from 'react';
import items from '@static/cms/pages/portfolio.json';
import CasesCarousel from '../cases-carousel/CasesCarousel';

const RecommendedCases = ({ slug }) => {
	const caseItem = items.items.find((item) => item.case === slug);
	if (!caseItem) {
		return null;
	}

	const { industries, capabilities } = caseItem;
	const cases = getRelatedCases(items, capabilities, industries, slug, NUMBER_OF_CASES);
	const title = 'Check These Out';
	return <CasesCarousel title={title} cases={cases} />;
};

RecommendedCases.propTypes = {};

export default RecommendedCases;
