import Container from '@components/container';
import Intro from '@components/intro';
import VisibilitySensor from '@components/visibility-sensor';
import Picture from '@components/picture';
import { Link } from '@src/components';
import { Breakpoints, classNames } from '@utils';
import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import { Spring } from 'react-spring/renderprops';
import styles from './capabilities.module.scss';
import './swiper-active-bullet.scss';

function Capability(capability) {
	return (
		<>
			<Picture sources={capability.image} alt={capability.title} className={styles.defaultImage} />
			<Picture sources={capability.imageActive} alt={capability.title} className={styles.activeImage} />
			<p className={styles.title}>{capability.title}</p>
			<div className={styles.description}>
				<p>{capability.description}</p>
				<Link id={capability.id} to={capability.link} className={styles.link} new-tab>
					<span>+</span>
				</Link>
			</div>
		</>
	);
}

class Capabilities extends React.Component {
	constructor(props) {
		super();

		this.intro = props.intro;
		this.cards = props.cards;
		this.noMarginBottom = props.noMarginBottom;
		this.whiteBackground = props.whiteBackground;

		this.swiperParams = {
			loop: true,
			slidesPerView: 'auto',
			containerClass: classNames(styles.customSwiper, 'swiper-container'),
			getSwiper: this.getSwiper.bind(this),
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: false
			}
		};
	}

	getMobileSwipperParams = () => {
		this.swiperParams.pagination.clickable = false;
		return this.swiperParams;
	};

	getSwiper(swiper) {
		if (!swiper) {
			return false;
		}
		swiper.on('transitionEnd', () => {
			swiper.loopFix();
		});
	}

	render() {
		return (
			<>
				{this.intro && (
					<Intro {...this.intro} secondChild isFullContainer hasWhiteBackground={this.whiteBackground} />
				)}
				<Breakpoints>
					{(breakpoint) => {
						const isMobile = typeof window !== 'undefined' && window.innerWidth <= 375;
						const swiperParams = isMobile ? this.getMobileSwipperParams() : this.swiperParams;
						const carousel = ['xs', 'sm', 'md'].includes(breakpoint);
						const display = this.cards && this.cards.length > 0;
						const Wrapper = carousel
							? Swiper
							: ({ key, children }) => <React.Fragment key={key} children={children} />;

						return (
							display && (
								<Container
									className={classNames(
										styles.container,
										this.noMarginBottom && styles.noMarginBottom,
										this.whiteBackground && styles.whiteBackground,
										carousel && styles.capabilitiesCarousel
									)}
								>
									<Wrapper {...swiperParams}>
										{this.cards.map((capability, i) =>
											carousel ? (
												<div className={styles.capability} key={i}>
													<Capability {...capability} />
												</div>
											) : (
												<VisibilitySensor once key={i}>
													{({ isVisible }) => (
														<Spring
															config={{ duration: 600 }}
															delay={i * 150}
															to={{
																opacity: isVisible ? 1 : 0,
																transform: isVisible
																	? 'translateY(0)'
																	: 'translateY(80px)'
															}}
														>
															{(props) => (
																<div
																	className={styles.capabilityContainer}
																	key={i}
																	style={props}
																>
																	<div className={styles.capability}>
																		<Capability {...capability} />
																	</div>
																</div>
															)}
														</Spring>
													)}
												</VisibilitySensor>
											)
										)}
									</Wrapper>
								</Container>
							)
						);
					}}
				</Breakpoints>
			</>
		);
	}
}

export default Capabilities;
