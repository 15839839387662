import React from 'react';
import PropTypes from 'prop-types';

import Button from '@components/button';
import Picture from '@components/picture';
import styles from './scale-your-team.module.scss';

const ScaleYourTeam = ({ text, image, imageAlt, button }) => (


	<div className={styles.container}>
		<p className={styles.text}>{text}</p>
		<Button filled className={styles.button} link={button.link} title={button.title}>
			{button.title }
		</Button>
		{image && <Picture className={styles.illustration} sources={image} alt={imageAlt} />}
	</div>
);

ScaleYourTeam.propTypes = {
	text: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	imageAlt: PropTypes.string.isRequired,
	button: PropTypes.object.isRequired
};

export default ScaleYourTeam;
