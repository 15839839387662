import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/link';
import Card from './card';
import styles from './digital-product-development.module.scss';

const DigitalProductDevelopment = ({ text, cards }) => (
	<div>
		<p className={styles.text}>{text}</p>
		<div className={styles.cardsContainer}>
			{cards.map(({ images, title, text, links }) => {
				const isClickeable = links.length === 1;

				return (
					<Card isClickeable={isClickeable} key={text} links={links}>
						<div className={styles.imageContainer}>
							{images && images.map((image) => (
								<img
									data-src={image}
									key={image}
									alt={text}
									className="lazyload"
									height="auto"
									width="auto"
								/>
							))}
						</div>
						<p className={styles.cardTitle}>{title}</p>
						<p className={styles.cardText}>{text}</p>
						{!isClickeable &&
							links.map(({ url, title }) => (
								<Link key={title} className={styles.linkText} to={url}>
									{title}
								</Link>
							))}
					</Card>
				);
			})}
		</div>
	</div>
);

DigitalProductDevelopment.propTypes = {
	text: PropTypes.string.isRequired,
	cards: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DigitalProductDevelopment;
