import React, { useState } from 'react';
import Container from '../container';
import { Spring } from 'react-spring/renderprops';
import Section from './Section';
import styles from './quiz.module.scss';
export default function Quiz({ sections }) {
	const [result, setResult] = useState(0);
	const [answers, setAnswers] = useState({});
	const [showResults, setShowResults] = useState(false);
	const calculate = () => {
		setResult(
			Object.keys(answers).reduce((res, k) => res + Object.values(answers[k]).reduce((res, k) => res + k, 0), 0)
		);
		setShowResults(true);
	};

	const reset = () => {
		setAnswers({});
		setShowResults(false);
	};

	const answerSection = answer => setAnswers({ ...answers, ...answer });

	return (
		<Container>
			{!showResults &&
				sections.map((section, ix) => <Section key={ix} id={ix} {...section} answerSection={answerSection} />)}
			<div className={styles.quiz}>
				{!showResults ? (
					<button className={styles.button} onClick={() => calculate()}>
						How usable am I?
					</button>
				) : (
					<div className={styles.results}>
						<p className={styles.result}>
							You are
							<Spring config={{ duration: 700 }} from={{ number: 0 }} to={{ number: result }}>
								{props => <span> {Math.round(props.number)}% </span>}
							</Spring>
							usable.
						</p>
						<img className={styles.picture} src={'/cms/assets/cap-discovery-how-we-do-it.svg'} />
						<button className={styles.button} onClick={() => reset()}>
							Try Again
						</button>
					</div>
				)}
			</div>
		</Container>
	);
}
