import React from 'react';

import Layout from '@components/layout';
import Heading from '@components/heading';
import OfferingIntro from '@components/offering-intro';
import YouAreHere from '@components/you-are-here';
import TitleWithChecklist from '@components/title-with-checklist';
import Intro from '@components/intro';
import ProductFeaturesGrid from '@components/product-features-grid';
import CasesPortfolioView from '@components/cases-portfolio-view';
import Tools from '@components/tools';
import ContactUs from '@components/contact-us';
import styles from './product-strategy.module.scss';

const ProductStrategy = (props) => {
	const { location, pageContext } = props;
	const { title, intro, benefits, tools } = pageContext;
	const { img: howWeDoItImg, ...howWeDoIt } = pageContext['how-we-do-it'];

	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} h1Title imageAlt={'Product Strategy | Uruit'} />
			<OfferingIntro {...intro} />
			<YouAreHere />
			{pageContext['second-intro'] && <Intro {...pageContext['second-intro']} h1Title hasWhiteBackground />}
			{benefits && <TitleWithChecklist {...benefits} />}
			{howWeDoItImg &&
				<div className={styles.parallax} style={{ backgroundImage: `url(${howWeDoItImg})`}}/>}
			{howWeDoIt && <Intro {...howWeDoIt} h2Title hasWhiteBackground is8ColumnsWidthDescription />}
			{pageContext['product-features'] && <ProductFeaturesGrid {...pageContext['product-features']} />}
			{pageContext['case-studies'] && <CasesPortfolioView showLinkToPortfolio {...pageContext['case-studies']} />}
			{tools && <Tools className={styles.tools} {...tools} />}
			<ContactUs id="contact-from-product-strategy" pathname={location.pathname} />
		</Layout>
	);
};

export default ProductStrategy;
