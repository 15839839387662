import React from 'react';
import { Layout, Heading, Container, Content } from '@components';
import { ReactMarkdown } from '@components';

const Page = (props) => {
	const { location, pageContext } = props;
	const { title, subtitle, content } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} subtitle={subtitle} />
			<Container>
				<Content>
					<ReactMarkdown children={content} />
				</Content>
			</Container>
		</Layout>
	);
};

export default Page;
