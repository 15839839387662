import { Layout } from '@components';
import CaseSection, { CaseSectionCarousel, CaseSectionImage } from '@components/case-section';
import Container from '@components/container';
import ContactUs from '@components/contact-us';
import layoutStyles from '@components/layout/layout.module.scss';
import Media from '@components/media';
import RecommendedCases from '@components/recommended-cases/RecommendedCases';
import { classNames } from '@utils';
import React from 'react';
import styles from './case.module.scss';

const Case = (props) => {
	const { location, pageContext } = props;
	const { title, image, color, slug } = pageContext;

	const hasSection = (name) => !!pageContext[name] && !!Object.keys(pageContext[name]).length;
	return (
		<Layout location={location} pageContext={pageContext} headerWhite={true} noMarginTop>
			<section className={styles.heading} style={{ backgroundColor: color }}>
				{image && (
					<Media
						image={image}
						cover
						alt={pageContext['seo']['title-tag'] + ' | ' + pageContext['seo']['title-tag-after']}
					/>
				)}
				<Container className={classNames(layoutStyles.headerPad, styles.headingContainer)}>
					<h1 className={styles.title}>{title}</h1>
				</Container>
			</section>
			{hasSection('about') && <CaseSection sectionId="about" {...pageContext['about']} color={color} />}
			{!!pageContext['image-section'] && (
				<CaseSectionImage
					sectionId="image"
					image={pageContext['image-section']}
					color={color}
					width100={!!pageContext['image-section-width-100']}
				/>
			)}
			{hasSection('challenge') && (
				<CaseSection sectionId="challenge" {...pageContext['challenge']} color={color} />
			)}
			{!!pageContext['carousel-section-1'] && (
				<CaseSectionCarousel
					sectionId="carousel-1"
					image={pageContext['carousel-section-1']}
					addPaddingBottom
				/>
			)}
			{hasSection('solution') && <CaseSection sectionId="solution" {...pageContext['solution']} color={color} />}
			{!!pageContext['carousel-section-2'] && (
				<CaseSectionCarousel
					sectionId="carousel-2"
					image={pageContext['carousel-section-2']}
					addPaddingBottom
				/>
			)}
			{hasSection('results') && <CaseSection sectionId="results" {...pageContext['results']} color={color} />}
			{!!pageContext['carousel-section-3'] && (
				<CaseSectionCarousel sectionId="carousel-3" image={pageContext['carousel-section-3']} />
			)}
			<RecommendedCases slug={slug} />
			<ContactUs id="contact-from-full-product" pathname={location.pathname} />
		</Layout>
	);
};

export default Case;
