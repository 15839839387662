import BannerCta from '@components/banner/BannerCta';
import Filters from '@components/filters';
import GridArea from '@components/grid-area';
import Heading from '@components/heading';
import Layout from '@components/layout';
import { Capabilities, Industries, ItemTypes } from '@utils';
import React, { useCallback, useEffect, useState } from 'react';

const Portfolio = ({ location, pageContext }) => {
	const { title, sectionTitle, items } = pageContext;
	const [filteredItems, setFilteredItems] = useState(null);
	const [filters, setFilters] = useState(null);
	const [cases, setCases] = useState(null);
	const [animate, setAnimate] = useState(false);

	const filterHandler = useCallback((tags) => {
		// Delays the filtering to avoid interference with the animation
		setTimeout(() => {
			handleFilterCases(tags);
		}, 900);
		animateHandler();
	});

	const animateHandler = useCallback(() => {
		setAnimate(true);
		setTimeout(() => {
			setAnimate(false);
		}, 1800);
	});

	const handleFilterCases = useCallback(
		(tagsSelected) => {
			const itemsToShow =
				tagsSelected.length > 0
					? cases.filter(
							({ capabilities, industries }) =>
								capabilities.find((c) => tagsSelected.includes(c)) ||
								industries.find((i) => tagsSelected.includes(i))
					  )
					: items;
			setFilteredItems(itemsToShow);
		},
		[items, cases]
	);

	useEffect(() => {
		setCases(items.filter((i) => i.type === ItemTypes.CASE));
		setFilteredItems(items);

		const industriesTags = Object.entries(Industries).map(([key, value]) => ({ key, value }));
		const capabilitiesTags = Object.entries(Capabilities).map(([key, value]) => ({ key, value }));
		setFilters([
			{ label: 'Industries', tags: industriesTags },
			{ label: 'Capabilities', tags: capabilitiesTags }
		]);
	}, [items]);

	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} h1Title imageAlt={'Software Development Company Portfolio | Uruit'} />
			{filters && <Filters sectionTitle={sectionTitle} content={filters} onFilter={filterHandler} />}
			{filteredItems && <GridArea animate={animate} items={filteredItems} />}
			<BannerCta
				id="get-started-portfolio"
				title="Want to discuss your project?"
				link="/contact"
				cta="Let's get started"
			/>
		</Layout>
	);
};

export default Portfolio;
