import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/link';
import styles from './card.module.scss';

const Card = ({ isClickeable, links, children }) =>
	isClickeable ? (
		<Link to={links[0].url} className={styles.clickeableCard}>
			{children}
		</Link>
	) : (
		<div className={styles.card}>{children}</div>
	);

Card.propTypes = {
	isClickeable: PropTypes.bool,
	links: PropTypes.array,
	children: PropTypes.node.isRequired
};

Card.defaultProps = {
	isClickeable: false,
	links: []
};

export default Card;
