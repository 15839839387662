import React from 'react';
import BannerCta from '@components/banner/BannerCta';
import Layout from '@components/layout';
import Heading from '@components/heading';
import Intro from '@components/intro';
import Trajectory from '@components/trajectory';
import ProductFeatures from '@components/product-features';
import Recognition from '@components/recognition';

const AboutUs = (props) => {
	const { location, pageContext } = props;
	const { title, subtitle } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext} offices={false}>
			<Heading title={title} subtitle={subtitle} h2Title h3Subtitle />
			<Intro
				{...pageContext.intro}
				illustrations={['/cms/assets/team_uruguay.webp', '/cms/assets/team_colombia.webp']}
				h1Title
				imageAlt={'Nearshore Development Firm | Uruit'}
			/>
			<ProductFeatures {...pageContext['product-features']} />
			<Trajectory {...pageContext['trajectory']} />
			<Recognition {...pageContext['recognitions']} />
			<BannerCta id="get-started-about-us" title="Like what you see?" link="/contact" cta="Let's get started" />
		</Layout>
	);
};

export default AboutUs;
