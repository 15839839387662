import Container from '@components/container';
import React, { useEffect, useState } from 'react';
import Jobs from './Jobs';

const JobsWrapper = props => {
	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getJobs = async () => {
			const url = `${process.env.WEB_NODE_SERVER}/careers`;
			const response = await fetch(url, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(res => res.json());
			setJobs(response.jobs.map(job => ({ ...job, link: job.url })));
			setLoading(false);
		};

		getJobs();
	}, []);

	return loading ? <Container>Loading...</Container> : <Jobs {...props} jobs={jobs} />;
};

export default JobsWrapper;
