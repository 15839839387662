import BlogPosts from '@components/blog-posts';
import CarouselQuote from '@components/carousel-quote';
import CasesCarousel from '@components/cases-carousel';
import ContactUs from '@components/contact-us';
import Intro from '@components/intro';
import Layout from '@components/layout';
import ProductFeatures from '@components/product-features';
import WeAre from '@components/we-are';
import React from 'react';

const StaffAugmentation = (props) => {
	const { location, pageContext } = props;
	const casesCarousel = pageContext['casesCarousel'];
	const blogPosts = pageContext['blog-posts'];
	return (
		<Layout location={location} pageContext={pageContext}>
			<WeAre
				animate
				{...pageContext['we-are']}
				intro={true}
				h2Title
				h2Subtitle
				imageAlt={'Nearshore Staff Augmentation | Uruit'}
			/>
			<Intro {...pageContext.intro} h1Title />
			<ProductFeatures {...pageContext['product-features']} twoColumns bullets={true} tabletDefault />
			<ContactUs id="contact-from-staff-aug" pathname={location.pathname} />
			<CarouselQuote {...pageContext['reviews']} />
			{!!casesCarousel && !!casesCarousel.show && (
				<CasesCarousel slugs={casesCarousel.cases} title={casesCarousel.title} />
			)}
			{!!blogPosts && !!blogPosts.show && <BlogPosts posts={blogPosts.posts} title={blogPosts.title} />}
		</Layout>
	);
};

export default StaffAugmentation;
