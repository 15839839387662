import React from 'react';
import PropTypes from 'prop-types';
import Section from '@components/section';
import SectionTitle from '@components/section-title';
import MachineLearningConsulting from '@components/machine-learning-consulting';
import Tabs from '@components/tabs';
import DigitalProductDevelopment from '@components/digital-product-development';
import ScaleYourTeam from '@components/scale-your-team';
import styles from './services.module.scss';

const OurServices = ({ sectionTitle, digitalProductDevelopment, machineLearningConsulting, scaleYourTeam }) => {
	const tabs = [
		{
			title: scaleYourTeam.title,
			children: <ScaleYourTeam {...scaleYourTeam} />
		},
		{
			title: digitalProductDevelopment.title,
			children: <DigitalProductDevelopment {...digitalProductDevelopment} />
		}
	];
	if(machineLearningConsulting) {
		tabs.push({
			title: machineLearningConsulting.title,
			children: <MachineLearningConsulting {...machineLearningConsulting} />
		});
	}
	return (
		<Section isHome>
			<div className={styles.container}>
				<SectionTitle title={sectionTitle} />
				<Tabs tabs={tabs} />
			</div>
		</Section>
	);
};

OurServices.propTypes = {
	sectionTitle: PropTypes.string.isRequired,
	digitalProductDevelopment: PropTypes.object.isRequired,
	machineLearningConsulting: PropTypes.object,
	scaleYourTeam: PropTypes.object.isRequired
};

export default OurServices;
