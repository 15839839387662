import React from 'react';
import AsSeenOn from '@components/as-seen-on';
import BannerCentered from '@components/banner-centered';
import CarouselQuoteNew from '@components/carousel-quote-new';
import CasesPortfolioViewHome from '@components/cases-portfolio-view-home';
import Clients from '@components/clients';
import HowWeDoIt from '@components/how-we-do-it';
import Layout from '@components/layout';
import Services from '@components/services';
import WeAreHome from '@components/we-are-home';
import ClientsHighlighted from '@components/clients-highlighted';
import { Breakpoints } from '@utils';

const Home = (props) => {
	const { location, pageContext } = props;
	const { banner1 } = pageContext;
	const reviewItem = {
		item: pageContext['reviews'].items[0],
		title: pageContext['reviews'].title,
		description: pageContext['reviews'].description
	};
	return (
		<Layout location={location} pageContext={pageContext}>
			{pageContext['we-are'] && (
				<Breakpoints>
					{(breakpoint) => {
						const column = ['xs', 'sm'].includes(breakpoint);
						return (
							<WeAreHome
								animate
								{...pageContext['we-are']}
								illustrations={'/cms/assets/home-illustration-xl.webp'}
								column={column}
								home
								h2Title
								asLinkH2Title
								linkH2To="/portfolio"
								h2Subtitle
								imageAlt={'Nearshore Outsourcing | Uruit'}
							/>
						);
					}}
				</Breakpoints>
			)}
			{pageContext['clients'] && <Clients {...pageContext['clients']} />}
			{pageContext['services'] && <Services {...pageContext['services']} />}
			{pageContext['clients-highlighted'] && <ClientsHighlighted {...pageContext['clients-highlighted']} />}
			{banner1 && <BannerCentered text={banner1} isHome />}
			{pageContext['howWeDoIt'] && <HowWeDoIt {...pageContext['howWeDoIt']} />}
			{pageContext['reviews'] && <CarouselQuoteNew {...reviewItem} />}
			{pageContext['as-seen-on'] && <AsSeenOn {...pageContext['as-seen-on']} />}
			{pageContext['success-stories'] && (
				<CasesPortfolioViewHome onlySectionTitle {...pageContext['success-stories']} />
			)}
		</Layout>
	);
};

export default Home;
