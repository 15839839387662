import React from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '@components/visibility-sensor';
import styles from './milestone.module.scss';
import { classNames } from '@utils';

const Milestone = props => {
	const { order, title, subtitle, description } = props;
	const titleNumber = +title.replace('+', '');

	return (
		<div className={styles.container}>
			<div className={classNames(styles.title, title.includes('+') && styles.titlePlus)}>
				<VisibilitySensor once>
					{({ isVisible }) => (
						<Spring
							config={{ duration: order * 500 + 2000 }}
							from={{ number: 0 }}
							to={{ number: isVisible ? titleNumber : 0 }}
						>
							{props => <div>{Math.round(props.number)}</div>}
						</Spring>
					)}
				</VisibilitySensor>
				{title.includes('+') && <p className={styles.titlePlus}>+</p>}
			</div>
			<div className={styles.subtitle}>
				<p>{subtitle}</p>
			</div>
			<div className={styles.description}>
				<p>{description}</p>
			</div>
		</div>
	);
};

Milestone.propTypes = {
	order: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired
};

export default Milestone;
