import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styles from './tab.module.scss';
import { classNames } from '@utils';

const Tab = ({ id, isActive, title, children, onSelectedTab }) => {
	const handleClick = useCallback(() => {
		if (!isActive) {
			onSelectedTab(id);
		}
	});

	return (
		<div className={classNames(styles.tab, isActive && styles.isActive)}>
			<div className={styles.title} onClick={handleClick}>
				{title}
			</div>
			<div className={styles.children}>{children}</div>
		</div>
	);
};

Tab.propTypes = {
	id: PropTypes.number.isRequired,
	isActive: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	onSelectedTab: PropTypes.func.isRequired
};

export default Tab;
