import Icon from '@components/icon';
import Link from '@components/link';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './jobs.module.scss';

const Job = props => {
	return (
		<div className={styles.job}>
			<div className={classNames(styles.jobGrid, styles.jobGridFigure)}>
				<figure className={styles.jobFigure}>{props.icon && <Icon name={`job-${props.icon}`} />}</figure>
			</div>
			<div className={classNames(styles.jobGrid, styles.jobGridTitle)}>
				<h4 className={styles.jobTitle}>{props.title}</h4>
			</div>
			<div className={classNames(styles.jobGrid, styles.jobGridLocation)}>
				{/* TODO: Uncomment once jobs can be set to two different locations on workable */}
				{/* <span className={styles.jobLocation}>
					{!!props.location && (
						<React.Fragment>
							<span className={styles.jobLocationIcon}>
								<Icon name="pin-marker" />
							</span>
							<span className={styles.jobLocationText}>{props.location}</span>
						</React.Fragment>
					)}
				</span> */}
			</div>
			<div className={classNames(styles.jobGrid, styles.jobGridLink)}>
				{props.link && (
					<Link className={styles.jobLink} to={props.link} target="_blank" rel="nofollow norel">
						See more
					</Link>
				)}
			</div>
		</div>
	);
};

Job.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	location: PropTypes.string,
	link: PropTypes.string
};

Job.defaultProps = {
	icon: 'dev'
};

export default Job;
