import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Heading, Section, Container, Content, Summary } from '@components';
import { ReactMarkdown } from '@components';

const SummarizedContent = (props) => {
	const { location, pageContext } = props;
	const { title, subtitle, content, summary } = pageContext;
	return (
		<Layout location={location} pageContext={pageContext}>
			<Heading title={title} subtitle={subtitle} />
			<Section>
				<Container small>
					<Content>
						<ReactMarkdown children={content} />
					</Content>
					<Summary title="Table of contents" items={summary} />
				</Container>
			</Section>
		</Layout>
	);
};

SummarizedContent.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	content: PropTypes.string,
	summary: PropTypes.arrayOf(PropTypes.object)
};

export default SummarizedContent;
