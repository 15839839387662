import React from 'react';
import PropTypes from 'prop-types';
import items from '@static/cms/pages/portfolio.json';
import Button from '@components/button';
import SectionDescription from '@components/section-description';
import SectionTitle from '@components/section-title';
import GridArea from '@components/grid-area';
import styles from './cases-portfolio-view-home.module.scss';

const CasesPortfolioViewHome = ({ cases, onlySectionTitle, showLinkToPortfolio, ...restProps }) => {
	const casesData = items ? items.items.filter(item => item.case === cases.case1 || item.case === cases.case2) : [];
	const buttonText = 'Visit Our Portfolio';

	return (
		<section className={styles.section, styles.parallax}>
			<div className={styles.content}>
				{onlySectionTitle ? (
					<SectionTitle {...restProps} h2Title />
				) : (
					<SectionDescription {...restProps} h2Title />
				)}
			</div>
			<GridArea items={casesData} />
			{showLinkToPortfolio && (
				<div className={styles.linkContainer}>
					<div className={styles.linkText}>Can't find what you’re looking for?</div>
					<Button className={styles.button} title={buttonText} filled link="/portfolio">
						{buttonText}
					</Button>
				</div>
			)}
		</section>
	);
};

CasesPortfolioViewHome.propTypes = {
	cases: PropTypes.shape({
		case1: PropTypes.string.isRequired,
		case2: PropTypes.string.isRequired
	}).isRequired,
	onlySectionTitle: PropTypes.bool,
	showLinkToPortfolio: PropTypes.bool
};

CasesPortfolioViewHome.defaultProps = {
	onlySectionTitle: false,
	showLinkToPortfolio: false
};

export default CasesPortfolioViewHome;
