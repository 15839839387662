import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@components';
import { ReactMarkdown } from '@components';
import { classNames } from '@utils';
import styles from './case-section.module.scss';
import Content from '@components/content';

export const CaseSectionCarousel = (props) => {
	const { image, addPaddingBottom } = props;
	return image ? (
		<section>
			<img
				data-src={image}
				key={image}
				className={classNames(addPaddingBottom && styles.imagePaddingBottom, 'lazyload')}
				height="auto"
				width="100%"
			/>
		</section>
	) : null;
};

CaseSectionCarousel.propTypes = {
	image: PropTypes.string,
	sectionId: PropTypes.string.isRequired
};

CaseSectionCarousel.defaultProps = {
	sectionId: 'carousel'
};

export const CaseSectionImage = (props) => {
	const { color, image, imageAlt, width100 } = props;
	return image ? (
		<section className={styles.imageSection} style={{ color }}>
			<Container
				className={classNames(styles.container, styles.imageSectionContainer, width100 && styles.width100)}
			>
				<img src={image} alt={imageAlt} className={classNames(width100 && styles.width100)} />
			</Container>
		</section>
	) : null;
};

CaseSectionImage.propTypes = {
	color: PropTypes.string,
	image: PropTypes.string,
	sectionId: PropTypes.string.isRequired
};

CaseSectionImage.defaultProps = {
	sectionId: 'image'
};

const Title = (props) => {
	const { color, className, sectionId, sectionTitle, title } = props;
	const isChallengeSection = sectionId.toLowerCase() === 'challenge';
	return !!sectionTitle || !!title ? (
		<div className={className}>
			{!!sectionTitle && (
				<div className={styles.sectionTitleContainer}>
					<div
						className={styles.sectionTitleLine}
						style={{ borderColor: !isChallengeSection ? color : null }}
					/>
					<h4 className={styles.sectionTitle}>{sectionTitle}</h4>
				</div>
			)}
			{!!title && <h2 className={styles.title}>{title}</h2>}
		</div>
	) : null;
};

Title.propTypes = {
	color: PropTypes.string,
	className: PropTypes.string,
	sectionTitle: PropTypes.string,
	title: PropTypes.string
};

const SolutionSection = (props) => {
	const { description } = props;
	return (
		<React.Fragment>
			<Title {...props} className={styles.card} />
			{!!description && (
				<Container className={classNames(styles.container, styles.descriptionContainer)}>
					<Content>
						<ReactMarkdown children={description} className={styles.description} newTab />
					</Content>
				</Container>
			)}
		</React.Fragment>
	);
};

SolutionSection.propTypes = {
	color: PropTypes.string,
	description: PropTypes.string,
	sectionTitle: PropTypes.string,
	title: PropTypes.string
};

const CommonSection = (props) => {
	const { description } = props;
	return (
		<Container className={styles.container}>
			<Title {...props} className={styles.commonSectionTitleContainer} />
			{!!description && (
				<div className={styles.descriptionContainer}>
					<Content>
						<ReactMarkdown children={description} className={styles.description} newTab />
					</Content>
				</div>
			)}
		</Container>
	);
};

CommonSection.propTypes = {
	color: PropTypes.string,
	description: PropTypes.string,
	sectionTitle: PropTypes.string,
	title: PropTypes.string
};

const CaseSection = (props) => {
	const { color, description, sectionId, sectionTitle, title } = props;
	const isSection = (sectionName) => sectionId.toLowerCase() === sectionName;
	const isChallengeSection = isSection('challenge') && color;
	const isResultsSection = isSection('results');
	const isSolutionSection = isSection('solution');
	return !!sectionTitle || !!title || !!description ? (
		<section
			className={classNames(
				styles.section,
				isChallengeSection && styles.challengeSection,
				isResultsSection && styles.resultsSection,
				isSolutionSection && styles.solutionSection
			)}
			style={{ backgroundColor: isChallengeSection ? color : null }}
		>
			{isSolutionSection ? <SolutionSection {...props} /> : <CommonSection {...props} />}
		</section>
	) : null;
};

CaseSection.propTypes = {
	color: PropTypes.string,
	description: PropTypes.string,
	sectionId: PropTypes.string,
	sectionTitle: PropTypes.string,
	title: PropTypes.string
};

CaseSection.defaultProps = {
	color: '#00d0ad',
	sectionId: ''
};

export default CaseSection;
