import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './filter.module.scss';
import Tags from '@components/tags';
import { classNames } from '@utils';

const Filter = ({ label, words, onFilter, isClickeable, className }) => {
	const [isActive, setIsActive] = useState(false);
	const node = useRef();

	const handleClick = useCallback(() => {
		setIsActive(!isActive);
	}, [isActive]);

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			return;
		}
		setIsActive(!isActive);
	};

	useEffect(() => {
		if (isActive) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isActive]);

	return (
		words && (
			<div ref={node} className={classNames(styles.row, className)}>
				<span onClick={handleClick} className={styles.label}>
					{label}
				</span>
				<Tags
					words={words}
					onFilter={onFilter}
					isClickeable={isClickeable}
					className={isActive ? styles.show : null}
				/>
			</div>
		)
	);
};

Filter.propTypes = {
	label: PropTypes.string,
	words: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }).isRequired),
	onFilter: PropTypes.func,
	isClickeable: PropTypes.bool,
	className: PropTypes.string
};

Filter.defaultProps = {
	onFilter: null,
	isClickeable: false,
	className: null
};

export default Filter;
