import React from 'react';

import Layout from '@components/layout';
import Heading from '@components/heading';
import Intro from '@components/intro';
import List from '@components/list';
import Container from '@components/container';
import ContactUs from '@components/contact-us';
import ProductFeatures from '@components/product-features';
import OtherCapabilities from '@components/capabilities';
import Tools from '@components/tools';
import styles from './capabilities-web.module.scss';

const theme = {
	container: styles.footerContainer
};

const introTheme = {
	section: styles.introSection
};

const CapabilitiesWeb = props => {
	const { location, pageContext } = props;
	const { title, subtitle, benefits } = pageContext;
	const { title: introListTitle, list: introList } = pageContext['intro-list'];
	const { title: benefitsTitle, ...otherBenefitsProps } = benefits;
	const { topImg: topImgHowWeDoIt, ...howWeDoItBody } = pageContext['how-we-do-it'];

	return (
		<Layout location={location} pageContext={pageContext} offices={false} theme={theme}>
			<Heading title={title} subtitle={subtitle} h3Subtitle reverse />
			<Intro
				{...pageContext.intro}
				hasWhiteBackground
				theme={introTheme}
				is8ColumnsWidthTitle
				is8ColumnsWidthDescription
			/>
			<Container small>
				<h3 className={styles.subtitle}>{introListTitle}</h3>
				<List {...introList} />
				<h2 className={styles.title}>{benefitsTitle}</h2>
			</Container>
			<ProductFeatures {...otherBenefitsProps} twoColumns bullets={true} tabletDefault />
			{topImgHowWeDoIt &&
				<div className={styles.parallax} style={{ backgroundImage: `url(${topImgHowWeDoIt})`}}/>}
			<ProductFeatures {...howWeDoItBody['how-we-do-it-body']} twoColumns tabletDefault />
			<ProductFeatures {...pageContext['case-studies']} twoColumns tabletDefault hideCarousel />
			<Tools {...pageContext['tools']} />
			<ContactUs id="contact-from-capabilities-web" pathname={location.pathname} />
			<OtherCapabilities {...pageContext['other-capabilities']} positionAbsolute />
		</Layout>
	);
};

export default CapabilitiesWeb;
