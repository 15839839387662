import React from 'react';
import PropTypes from 'prop-types';
import Picture from '@components/picture';
import Link from '@components/link';
import styles from './clients-highlighted.module.scss';

const Clients = (props) => {
	const { image, name, link } = props;
	return !!image ? (
		<div>
			<div className={styles.imageSection}>
				<Picture sources={image} alt={name} />
			</div>
			<div className={styles.linkSection}>
				<Link to={link} className={styles.link}>VIEW CASE STUDY</Link>
			</div>
		</div>
	) : null;
};

const ClientsHighlighted = (props) => {
	const { title, items } = props;

	return !!items && items.length ? (
		<div className={styles.clientsHighlighted}>
			<div className={styles.title}>{title}</div>
			<div className={styles.images}>
				{items.map(item => Clients(item))}
			</div>
		</div>
	) : null;
};

ClientsHighlighted.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array
};

ClientsHighlighted.defaultProps = {
	title: ''
};

export default ClientsHighlighted;
