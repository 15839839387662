import React from 'react';

import SectionDescription from '@components/section-description';
import Picture from '@components/picture';
import styles from './l-a-headquarters.module.scss';

const LAHeadquarters = ({ image, imageAlt, ...restProps }) => (
	<section className={styles.section}>
		<div className={styles.media}>
			{image && <Picture imgClassName={styles.img} alt={imageAlt} sources={image} />}
		</div>
		<div className={styles.content}>
			<SectionDescription {...restProps} h2Title />
		</div>
	</section>
);

export default LAHeadquarters;
