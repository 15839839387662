import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@components/icon';
import ClutchWidget from '@components/clutch-widget';
import Quote from '@components/quote';
import SectionTitleVertical from '@components/section-title-vertical';
import SectionDescription from '@components/section-description';
import styles from './carousel-quote-new.module.scss';

const CarouselQuoteNew = ({ title, description, item }) => {
	return (
		<section className={styles.section}>
			<div className={styles.top}>
				<Icon name="quote" className={styles.icon} />
				<SectionTitleVertical className={styles.sectionTitle} title={title} />
			</div>
			<div className={styles.content}>
			    <SectionDescription className={styles.description} title={description}/>
				<Quote {...item} quote={item.quote} authorClassName={styles.quoteAuthor} />
				<ClutchWidget className={styles.widget} />
			</div>
		</section>
	);
};

CarouselQuoteNew.propTypes = {
	title: PropTypes.string.isRequired,
	item: PropTypes.shape({
		quote: PropTypes.string.isRequired,
		author: PropTypes.string.isRequired
	}).isRequired
};

export default CarouselQuoteNew;
