import React from 'react';
import PropTypes from 'prop-types';
import styles from './jobs.module.scss';
import Job from './Job';
import Container from '@components/container';

const formatJobLocation = job => ({
	...job,
	location: job.location.city ? `${job.location.city}, ${job.location.country}` : job.location.country
});

const Jobs = ({ title, jobs, noOpenPositions }) => {
	const jobsByCountry = jobs.reduce(
		(acc, currentJob) => {
			// TODO: Remove validation once jobs can be set to two different locations on workable
			const country = currentJob.location.country.includes('Colombia')
				? 'Colombia & Uruguay'
				: currentJob.location.country;
			const accJobsByCountry = acc[country];
			const currentJobsByCountry = accJobsByCountry
				? [...accJobsByCountry, formatJobLocation(currentJob)]
				: [formatJobLocation(currentJob)];
			return {
				...acc,
				[country]: currentJobsByCountry
			};
		},
		// TODO: Set to { Uruguay: [], Colombia: [] } once jobs can be set to two different locations on workable
		{ 'Colombia & Uruguay': [] }
	);

	return (
		<section className={styles.section}>
			<Container>
				<div className={styles.title}>{title}</div>
				<div className={styles.jobs}>
					{Object.keys(jobsByCountry).map((country, i) => (
						<div className={styles.location} key={i}>
							<h3 className={styles.locationTitle}>{country}</h3>
							<div className={styles.jobList}>
								{!!jobsByCountry[country].length ? (
									jobsByCountry[country].map((job, j) => (
										<div className={styles.jobWrapper} key={j}>
											<Job {...job} />
										</div>
									))
								) : (
									<div className={styles.jobWrapper}>
										<Job
											title={noOpenPositions.title}
											location={noOpenPositions.location[country.toLowerCase()]}
										/>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</Container>
		</section>
	);
};

Jobs.propTypes = {
	title: PropTypes.string.isRequired,
	jobs: PropTypes.array.isRequired,
	noOpenPositions: PropTypes.shape({
		title: PropTypes.string,
		location: PropTypes.shape({
			uruguay: PropTypes.string,
			colombia: PropTypes.string
		})
	}).isRequired
};

export default Jobs;
