import Button from '@components/button';
import Picture from '@components/picture';
import SectionTitle from '@components/section-title';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './how-we-do-it.module.scss';

const HowWeDoIt = ({ sectionTitle, image, imageAlt, steps, button, description }) => (
	<div className={styles.border}>
		<div className={styles.container}>
			<SectionTitle title={sectionTitle} />
			<div className={styles.grid}>
				{image && <Picture className={styles.illustration} sources={ image } alt={imageAlt} />}
				<div className={styles.steps}>
					{steps.map((step, i) => (
						<div key={i} className={styles.step}>
							<p className={styles.title}>{step.title}</p>
							<p className={styles.description}>{step.description}</p>
						</div>
					))}
				</div>
			</div>
			<div className={styles.buttonSection}>
				<p className={styles.sectionDescription}>{description}</p>
				<Button filled className={styles.button} link={button.link} title={button.title}>
					{button.title}
				</Button>
			</div>
		</div>
	</div>
);

HowWeDoIt.propTypes = {
	sectionTitle: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	imageAlt: PropTypes.string.isRequired,
	steps: PropTypes.array.isRequired,
	button: PropTypes.object.isRequired
};

export default HowWeDoIt;
